import React from 'react';
import { MetadataTypeAttributes } from 'types';
import EquipmentLinked from 'app/containers/Equipments/components/EquipmentLinked';
import { isEquipmentLinked } from 'app/containers/Equipments/utils';

type Props = {
  attribute: MetadataTypeAttributes;
};

function AttributeValue({ attribute }: Props) {
  if (!attribute.value || !attribute.displayValue) {
    return <>--</>;
  }

  if (isEquipmentLinked(attribute)) {
    return (
      <EquipmentLinked miId={attribute.value} label={attribute.displayValue} />
    );
  }

  return <>{attribute.displayValue}</>;
}

export default AttributeValue;
