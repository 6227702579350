import { format, parse, isValid } from 'date-fns';
import { enGB, fr, de, nl } from 'date-fns/locale';
import { timeFormatDefaultLocale } from 'd3-time-format';

import { USER_LANGUAGE_CODE, LANGUAGE_CODE } from 'utils/constants';
import { timescale_en_GB } from './d3-times-scales';

timeFormatDefaultLocale(timescale_en_GB);

const locales = { en: enGB, fr, de, nl };

const getLocale = (): string =>
  localStorage.getItem(USER_LANGUAGE_CODE) ||
  localStorage.getItem(LANGUAGE_CODE) ||
  'en';

// Parse date string from'dd-MM-yyyy' to Date object
export const parseDate = (date: string): Date => {
  return parse(date, 'dd-MM-yyyy', new Date());
};

const getDate = (date: Date | string): Date => {
  const parsedDate = date instanceof Date ? date : new Date(date);
  return isValid(parsedDate) ? parsedDate : new Date(NaN);
};

// Format date as a string based on formatStr and locale
const formatDateWithLocale = (
  date: Date | string,
  formatStr: string,
  locale: string,
): string => {
  const newDate = getDate(date);
  return isValid(newDate)
    ? format(newDate, formatStr, { locale: locales[locale] })
    : date.toString();
};

export const formatDate = (
  date: Date | string,
  formatStr: string = 'dd MMM y',
): string => formatDateWithLocale(date, formatStr, getLocale());

export const formatDateTime = (
  date: Date | string,
  formatStr: string = 'dd MMM y, HH:mm',
): string => formatDateWithLocale(date, formatStr, getLocale());

export const formatTime = (
  date: Date | string,
  formatStr: string = 'HH:mm',
): string => {
  const newDate = getDate(date);
  return isValid(newDate) ? format(newDate, formatStr) : date.toString();
};

export const isISODateString = (str: string) => {
  return /^(\d{4})-(\d{2})-(\d{2})T(\d{2}):(\d{2}):(\d{2}(?:\.\d*)?)Z$/gm.test(
    str,
  );
};
