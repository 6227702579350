import React from 'react';

import { translateAttributeLabel } from 'locales/translation';
import {
  Attribute,
  MaintainableItem,
  MaintainableItemMetadata,
  MetadataTypeAttributes,
  Value,
} from 'types';
import {
  DataTableColumn,
  DataTableColumnPreference,
  DataTableColumnType,
  DataTableRow,
} from 'app/components/DataTable/types';
import EquipmentLink from './components/EquipmentLink';
import { getPreferencesLocalStorage } from 'app/components/DataTable/utils';
import { EquipmentFilters } from './types';
import { hydrateEquipmentEnumLabels } from '../EquipmentDetail/utils';
import EquipmentLinked from './components/EquipmentLinked';

export const mapAttributeToDataTableColumn = (
  attr: MetadataTypeAttributes,
): DataTableColumn => ({
  order: attr.order,
  visible: attr.listVisible || false,
  type: attr.type as DataTableColumnType,
  id: attr.id,
  label: translateAttributeLabel(attr.id, attr.label),
  enumValues: attr.enumValues,
});

export const mapEquipmentToDataTableRow = (
  equipment: MaintainableItem,
): DataTableRow => ({
  id: equipment.id,
  values: equipment.attributes?.map(mapAttributeToDataTableValue(equipment)),
});

const mapAttributeToDataTableValue = (equipment: MaintainableItem) => (
  attr: Attribute,
) => {
  if (attr.id === 'name') {
    return {
      id: attr.id,
      value: <EquipmentLink miId={equipment.id} label={equipment.name} />,
    };
  }

  if (isEquipmentLinked(attr)) {
    return {
      id: attr.id,
      value: attr.value ? (
        <EquipmentLinked miId={attr.value} label={attr.displayValue} />
      ) : undefined,
    };
  }

  return {
    id: attr.id,
    value: attr.displayValue,
  };
};

export const filterOutAttachmentAttributes = (attr: MetadataTypeAttributes) =>
  !['file', 'image'].includes(attr.type);

export function reduceEnumLabels(metadata: MaintainableItemMetadata) {
  return metadata?.maintainableItemTypes
    .flatMap(miType =>
      miType.attributes.flatMap(attr =>
        attr.enumValues.map(enumValue => ({
          miType: miType.maintainableItemType,
          attributeId: attr.id,
          enumId: enumValue.id,
          enumLabel: enumValue.label,
        })),
      ),
    )
    .reduce(
      (acc, { miType, attributeId, enumId, enumLabel }) => ({
        ...acc,
        [`${miType}_${attributeId}_${enumId}`]: enumLabel,
      }),
      {},
    );
}

export function hydrateEquipments(
  equipments: MaintainableItem[],
  enumLabels: { [key: string]: string },
) {
  return equipments?.map(hydrateEquipmentEnumLabels(enumLabels));
}

export const getAttributesForMI = (
  id: string,
  equipments?: MaintainableItem[],
): Value[] => {
  const equipment = equipments?.find(e => e.id === id);
  return (
    equipment?.attributes
      ?.filter(attr => attr.value !== '')
      ?.map(attr => ({
        id: attr.id,
        value: attr.value,
        displayValue: attr.displayValue,
      })) || []
  );
};

export const getVisibleFilters = (
  filters: EquipmentFilters,
  miTypeId: string,
) => {
  const pref = getPreferencesLocalStorage(miTypeId);
  const hiddenColumns: DataTableColumnPreference[] = pref.filter(f => {
    return !f.visible;
  });
  const hiddenColumnIds: Array<string> = hiddenColumns.map(h => h.columnId);
  const updatedFilters = { ...filters };
  hiddenColumnIds.forEach(e => delete updatedFilters[e]);
  return updatedFilters;
};

export const isEquipmentLinked = ({ id }: { id: string }) =>
  ['replacedBy', 'replacementFor'].includes(id);
