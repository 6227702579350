import React, { useEffect, useCallback, useMemo } from 'react';
import styled from 'styled-components/macro';
import { useParams } from 'react-router-dom';

import { EntityType, HierarchyNode } from 'types';
import { useFetch } from 'utils/hooks/useFetch';
import { getHierarchyTree } from 'services/hierarchyServices';
import Select from 'app/components/Select';
import useSelection from '../useSelection';
import {
  flattenHierarchyTree,
  mapHierarchyNodeToSelectOption,
} from 'app/components/HierarchySelector/utils';

type Props = {
  name: string;
  selected: string[];
  onSubmit: (selected: string[]) => void;
};

const HierarchyFilter = ({ name, selected, onSubmit }: Props) => {
  const { locationId } = useParams() as { locationId: string };
  const { toggle, selected: staged, clear, select } = useSelection();

  const { data } = useFetch<HierarchyNode>({
    fetchPromise: useCallback(
      () => getHierarchyTree(locationId, undefined, EntityType.SPACE),
      [locationId],
    ),
  });

  const options = useMemo(() => {
    return data
      ? flattenHierarchyTree([data], [EntityType.SPACE]).map(
          mapHierarchyNodeToSelectOption(EntityType.LOCATION),
        )
      : [];
  }, [data]);

  const handleClose = () => {
    clear();
    select(selected);
  };

  useEffect(() => {
    select(selected);
  }, [selected, select]);

  return (
    <Wrapper>
      <Select
        name={name}
        options={options}
        onSelect={toggle}
        selected={staged}
        onClose={handleClose}
        onSubmit={onSubmit}
      />
    </Wrapper>
  );
};

export default HierarchyFilter;

export const Wrapper = styled.div`
  width: 250px;
`;
