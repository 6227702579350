import {
  Attribute,
  AttributeGroup,
  MaintainableItem,
  MetadataTypeAttributes,
  Value,
} from 'types';
import { EquipmentFormAction, FormAttributeGroup } from './types';
import sortBy from 'lodash/sortBy';
import { filterOutAttachmentAttributes } from '../Equipments/utils';
import { formatDate } from 'locales/date-format-i18n';

export function getAttributeGroups(
  attributes: MetadataTypeAttributes[],
  attributeGroups: AttributeGroup[],
  filterField: 'visible' | 'listVisible',
): FormAttributeGroup[] {
  const attributesMap = new Map<string, MetadataTypeAttributes[]>();

  attributes
    .filter(attr => attr[filterField])
    .forEach(attr => {
      const existing = attributesMap.get(attr.attributeGroupId);
      attributesMap.set(
        attr.attributeGroupId,
        existing ? [...existing, attr] : [attr],
      );
    });

  function getSubGroups(parentId?: string) {
    return attributeGroups
      .filter(group => group.parentId === parentId)
      .map(group => ({
        ...group,
        attributes:
          sortBy(attributesMap.get(group.attributeGroupId), 'order') || [],
        subGroups: getSubGroups(group.attributeGroupId),
      }))
      .filter(group => group.attributes.length || group.subGroups.length);
  }

  return sortBy(getSubGroups(), 'sequence');
}

export const filterAttributeEdit = (action: EquipmentFormAction) => (
  attr: MetadataTypeAttributes,
) => action !== 'edit' || !['file', 'image'].includes(attr.type);

export const filterAttributeValueClone = (action: EquipmentFormAction) => (
  attr: Attribute,
) =>
  action !== 'clone' ||
  !['name', 'sapEquipmentId', 'eriksEquipmentId', 'tagId'].includes(attr.id);

export function hydrateAttributesWithValues(
  attributes: MetadataTypeAttributes[],
  attributeValues: Attribute[],
  action: EquipmentFormAction,
) {
  const valuesMap = new Map();
  attributeValues
    .filter(filterAttributeValueClone(action))
    ?.forEach(attr => valuesMap.set(attr.id, attr.value));

  return attributes.map(attr => ({
    ...attr,
    value: valuesMap.get(attr.id),
  }));
}

export const filterOutHierarchyAttributes = (attr: MetadataTypeAttributes) =>
  !['hierarchy'].includes(attr.type);

export function buildAttributesPayload(
  attributes: MetadataTypeAttributes[],
  formData: FormData,
  action: EquipmentFormAction,
  attrituteValues?: Attribute[],
): Value[] {
  const values: Value[] = [];

  attributes
    .filter(filterOutAttachmentAttributes)
    .filter(filterOutHierarchyAttributes)
    .forEach(attribute => {
      const valueFromFormData = formData.get(buildAttributeId(attribute));

      if (valueFromFormData) {
        values.push({ id: attribute.id, value: valueFromFormData.toString() });
      } else if (action === 'edit' && attrituteValues) {
        const attributeValue = attrituteValues.find(
          ({ id }) => id === attribute.id,
        );

        if (attributeValue?.value) {
          values.push({
            id: attributeValue.id,
            value: attributeValue.value,
            displayValue: attributeValue.displayValue,
          });
        }
      }
    });

  return values;
}

export function updateAttributesReplacedBy(
  attributeValues: Value[],
  equipment: MaintainableItem,
) {
  return attributeValues
    .map(({ id, value }) => ({ id, value }))
    .filter(
      ({ id }) =>
        ![
          'operatingStatus',
          'reasonForArchiving',
          'replacedBy',
          'archivedSince',
        ].includes(id),
    )
    .concat([
      {
        id: 'replacedBy',
        value: equipment.id,
        displayValue: equipment.name,
      } as Value,
      { id: 'operatingStatus', value: 'archived' },
      { id: 'archivedSince', value: formatDate(new Date(), 'dd-MM-yyyy') },
      {
        id: 'reasonForArchiving',
        value: `Replaced by ${equipment.name}`,
      },
    ]) as Value[];
}

export function updateAttributesReplacementFor(
  attributeValues: Value[],
  replacement: MaintainableItem,
) {
  return attributeValues
    .map(({ id, value }) => ({ id, value }))
    .filter(({ id }) => !['replacementFor'].includes(id))
    .concat([
      {
        id: 'replacementFor',
        value: replacement.id,
        displayValue: replacement.name,
      } as Value,
    ]) as Value[];
}

export const buildAttributeId = (attribute: MetadataTypeAttributes) =>
  `${attribute.id}#${attribute.attributeId}`;

export const isCouplingGroup = (group: FormAttributeGroup) =>
  group.attributeSubGroupType === 'rows';

export const isCombinedAttribute = (attribute: MetadataTypeAttributes) =>
  attribute.groupType === 'singleValue';

export const isSameRowAttribute = (attribute: MetadataTypeAttributes) =>
  attribute.groupType === 'ALL';
