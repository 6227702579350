import React, { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { EntityType, ParentTreeNode } from 'types';
import { buildEquipmentDetailRoute } from 'utils/route-helpers';
import { ButtonSecondary } from 'app/elements/Buttons';
import { actions } from 'app/containers/CustomerLocation/slice';
import { getParentTree } from 'services/hierarchyServices';
import { useFetch } from 'utils/hooks/useFetch';
import useEquipmentParams from '../hooks/useEquipmentParams';

type Props = {
  label: string;
  miId: string;
};

function EquipmentLinked({ label, miId }: Props) {
  const dispatch = useDispatch();
  const history = useHistory();
  const { customerId } = useEquipmentParams();
  const { doFetch } = useFetch<ParentTreeNode>({
    lazy: true,
    fetchPromise: useCallback(
      () => getParentTree(miId, undefined, [EntityType.LOCATION]),
      [miId],
    ),
  });

  const handleClick = async () => {
    const data = await doFetch();
    const locationId = data?.parent?.id;
    if (locationId) {
      dispatch(actions.setSelectedLocation(locationId));
      history.push(buildEquipmentDetailRoute(customerId, locationId, miId));
    }
  };

  return <ButtonSecondary onClick={handleClick}>{label}</ButtonSecondary>;
}

export default EquipmentLinked;
