import { useInjectReducer, useInjectSaga } from 'utils/redux-injectors';
import { reducer, sliceKey, actions } from '../slice';
import { equipmentsSaga } from '../saga';
import { useDispatch, useSelector } from 'react-redux';
import {
  selectAllEquipmentTypes,
  selectEquipmentTypes,
  selectIsLoadingEquipmentTypes,
} from '../selectors';
import { useCallback } from 'react';

type Props = {
  locationId?: string;
};

function useEquipmentTypes({ locationId }: Props) {
  useInjectReducer({ key: sliceKey, reducer: reducer });
  useInjectSaga({ key: sliceKey, saga: equipmentsSaga });

  const dispatch = useDispatch();

  const equipmentTypes = useSelector(selectEquipmentTypes);
  const allEquipmentTypes = useSelector(selectAllEquipmentTypes);
  const isLoadingEquipmentTypes = useSelector(selectIsLoadingEquipmentTypes);

  const fetchEquipmentTypes = useCallback(() => {
    if (locationId) {
      dispatch(actions.getEquipmentTypes(locationId));
    }
  }, [dispatch, locationId]);

  return {
    equipmentTypes,
    allEquipmentTypes,
    isLoadingEquipmentTypes,
    fetchEquipmentTypes,
  };
}

export default useEquipmentTypes;
