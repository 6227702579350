import sortBy from 'lodash/sortBy';
import { EntityType, HierarchyNode } from 'types';
import { CustomSelectOption } from '../Select/CustomSelect';
import { SelectOption } from '../Select';

export function flattenHierarchyTree(
  tree: HierarchyNode[],
  entityTypes: EntityType[],
): HierarchyNode[] {
  const filterNode = (node: HierarchyNode) =>
    entityTypes.includes(node.type as EntityType) ? [node] : [];

  return sortBy(tree, 'sequence').flatMap(node => {
    if (node.hasChildren) {
      return [
        ...filterNode(node),
        ...flattenHierarchyTree(node.children, entityTypes),
      ];
    }

    return filterNode(node);
  });
}

function getOptionDepth(firstNodeType: EntityType, node: HierarchyNode) {
  const offset =
    { [EntityType.CUSTOMER]: 2, [EntityType.LOCATION]: 1 }[firstNodeType] || 0;

  return node.type === EntityType.SPACE && node.depth ? node.depth - offset : 0;
}

export function mapHierarchyNodeToCustomSelectOption(
  firstNodeType: EntityType,
) {
  return function (node: HierarchyNode): CustomSelectOption {
    return {
      value: node.id,
      label: node.name || node.id,
      depth: getOptionDepth(firstNodeType, node),
    };
  };
}

export function mapHierarchyNodeToSelectOption(firstNodeType: EntityType) {
  return function (node: HierarchyNode): SelectOption {
    return {
      id: node.id,
      content: node.name,
      depth: getOptionDepth(firstNodeType, node),
    };
  };
}
